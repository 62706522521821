import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import home1 from "../../assets/home1.png";
import ContactUsModal from "../layout/ContactUsModal";
import FeaturesCard from "./FeaturesCard";
import PlansCard from "./PlansCard";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [showModal, setshowModal] = React.useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid container>
          <ContactUsModal
            show={showModal}
            handleClose={() => setshowModal(false)}
          />
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{ justifyContent: { xs: "center", md: "flex-start" }, pt: 5 }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#F47920",
                fontWeight: "1000",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              Make Things Happen with Sundeus CLM
            </Typography>
            <Stack
              sx={{
                my: 4,
                alignItems: {
                  xs: "center",
                  md: "flex-start",
                  lg: "flex-start",
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#0B132A",
                  fontWeight: "1000",
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                What CLM Offers
              </Typography>
              <Typography variant="body2" sx={{ textAlign: { xs: "left" } }}>
                Convert your contracts into strategic assets, by managing the contractual obligations using Sundeus Smart Al capabilities thereby minimizing contractual risks
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "#0B132A",
                  fontWeight: "1000",
                  textAlign: { xs: "center", md: "start" },
                  mt: 10
                }}
              >
                Collaborate & Negotiate faster with our Al assisted platform.
              </Typography>
              <Grid display='flex' flexDirection='row' justifyContent='space-around'>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => setshowModal(true)}
                    sx={{
                      borderRadius: "10px",
                      py: 2,
                      px: 7,
                      backgroundColor: "#F47920",
                      boxShadow: "0px 15px 20px rgba(244, 121, 32, 0.35)",
                      my: { xs: 5 },
                      textTransform: "capitalize",
                    }}
                  >
                    Request demo
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => navigate('/register')}
                    sx={{
                      borderRadius: "10px",
                      py: 2,
                      px: 7,
                      ml: 2,
                      backgroundColor: "#F47920",
                      boxShadow: "0px 15px 20px rgba(244, 121, 32, 0.35)",
                      my: { xs: 5 },
                      textTransform: "capitalize",
                    }}
                  >
                    Register for free
                  </Button>
                </Grid>
              </Grid>

            </Stack>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ pt: 3 }}>
            <Box
              component="img"
              src={home1}
              alt="Home Image"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: { xs: "100%" },
                height: { xs: "100%" },
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <FeaturesCard />

      <Box sx={{ p: 10, mt: 20 }}>
        <PlansCard />
      </Box>
      {/* <ReviveSection /> */}
    </>
  );
};

export default Home;
