import { useState } from 'react'
import { Box, Button, List, ListItem } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContactUsModal from '../layout/ContactUsModal'
import { useNavigate } from 'react-router-dom'

const cardData = [
    {
        title: "FREE",
        price: '$0',
        description: 'Ideal plan for personal use or solo enterpreneurs',
        features: [
            //   { feature: "Base Price", value: "FREE" },
            //   { feature: "Contract Types", value: "3" },
            //   { feature: "Monthly Contract Limit", value: "5" },
            { feature: "User Defined Reports", value: "NO" },
            { feature: "Standard Dashboards", value: "YES" },
            { feature: "Approvals", value: "NO" },
            { feature: "Electronic Signatures", value: "NO" },
            { feature: "Mobile App for Executives", value: "NO" },
            { feature: "Obligation Management", value: "NO" },
            { feature: "Clause Management", value: "NO" },
            { feature: "Artificial Intelligence (AI)", value: "NO" },
        ],
    },
    {
        title: "STANDARD",
        price: '$10',
        description: 'Designed for small and medium businesses (SMBs)',
        features: [
            { feature: "Base Price", value: "50 USD (Minimum 5 User)" },
            //   { feature: "Contract Types", value: "5" },
            //   { feature: "Monthly Contract Limit", value: "20" },
            { feature: "User Defined Reports", value: "YES" },
            { feature: "Standard Dashboards", value: "YES" },
            { feature: "Approvals", value: "YES" },
            { feature: "Electronic Signatures", value: "YES" },
            { feature: "Mobile App for Executives", value: "NO" },
            { feature: "Obligation Management", value: "NO" },
            { feature: "Clause Management", value: "NO" },
            { feature: "Artificial Intelligence (AI)", value: "YES" },
        ],
    },
    {
        title: "CUSTOM",
        price: '$$',
        description: 'Tailored for large businesses or unique requirements',
        features: [
            //   { feature: "Base Price", value: "GET IN TOUCH" },
            //   { feature: "Contract Types", value: "UNLIMITED" },
            //   { feature: "Monthly Contract Limit", value: "UNLIMITED" },
            { feature: "User Defined Reports", value: "YES" },
            { feature: "Standard Dashboards", value: "YES" },
            { feature: "Approvals", value: "YES" },
            { feature: "Electronic Signatures", value: "YES" },
            { feature: "Mobile App for Executives", value: "YES" },
            { feature: "Obligation Management", value: "YES" },
            { feature: "Clause Management", value: "YES" },
            { feature: "Artificial Intelligence (AI)", value: "YES" },
        ],
    }
]

const PlansCard = () => {
    const [showContactModal, setShowContactModal] = useState(false)
    const navigate = useNavigate();
    const handleClick = (title: string) => {
        if (title === 'STANDARD' || title === 'CUSTOM') {
            setShowContactModal(true)
        } else if (title === 'FREE') {
            navigate('/register')
        }
    }
    return (
        <>
            <Box sx={{ p: 1 }}>
                <Typography variant='h4' sx={{ color: 'black', fontWeight: 700, textAlign: 'center', pb: 3, py: { xs: 3 } }}>Start for Free, Upgrade Anytime!</Typography>
            </Box>
            <ContactUsModal show={showContactModal} handleClose={() => setShowContactModal(false)} />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 0, lg: 2, md: 4 }} xs={12} md={12} lg={12} sx={{ justifyContent: 'center', alignItems: 'stretch' }}>

                {cardData?.map((card, index) => (
                    <Grid key={index} item xs={12} md={6} lg={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card
                            sx={{
                                width: 400,
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                borderRadius: 4,
                                padding: 2,
                                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.5)',
                                // border: '2px solid #F47920', 
                                position: 'relative'
                            }}
                        >
                            {card.title === 'STANDARD' && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        backgroundColor: '#F47920',
                                        color: '#FFFFFF',
                                        padding: '4px 8px',
                                        borderRadius: '0 4px 4px 0',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}
                                >
                                    Most Popular
                                </Box>
                            )}
                            <CardContent sx={{ textAlign: 'left' }}>
                                {/* Title */}
                                <Typography variant="h6" sx={{ fontWeight: 700, mt: 2, mb: 2 }}>
                                    {card.title}
                                </Typography>

                                {/* Price */}
                                <Typography
                                    variant="h3"
                                    sx={{ fontWeight: 700, mb: 1, color: '#000000' }}
                                >
                                    {card.price}
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        sx={{ fontWeight: 600, fontSize: '16px', color: '#9E9E9E', ml: 1 }}
                                    >
                                        {card.title === 'FREE' ? 'free forever' : card.title === 'CUSTOM' ? '' : '/per month'}
                                    </Typography>
                                </Typography>
                                <Box sx={{ height: '5rem' }}>
                                    <Typography variant="body2" sx={{ color: '#9E9E9E', mb: 3 }}>
                                        {card.description}
                                    </Typography>
                                </Box>

                                {/* CTA Button */}
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "#F47920",
                                        color: '#FFFFFF',
                                        textTransform: 'none',
                                        fontWeight: 600,
                                        borderRadius: 4,
                                        boxShadow: 'none',
                                        mb: 3,
                                        '&:hover': {
                                            backgroundColor: '#4A4A4A',
                                        },
                                    }}
                                    onClick={() => handleClick(card.title)}
                                >
                                    {card.title === 'FREE' ? 'Try for Free' : 'Contact us'}
                                </Button>

                                {/* Features List */}
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ textAlign: 'left', mb: 2, color: '#9E9E9E' }}
                                    >
                                        FEATURES
                                    </Typography>
                                    <List disablePadding>
                                        {card.features?.map((feature, index) => (
                                            <ListItem
                                                key={index}
                                                sx={{
                                                    padding: 0,
                                                    mb: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                    }}
                                                >
                                                    {feature.value === 'YES' ? <CheckCircleIcon sx={{ color: '#36f73d', fontSize: 18 }} /> : <CancelIcon sx={{ color: '#f51b22', fontSize: 18 }} />}
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: '#000000',
                                                            lineHeight: 1.8,
                                                        }}
                                                    >
                                                        {feature.feature}
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default PlansCard