import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://clm.sundeus.com/api1/', // Replace with your API base URL
    timeout: 5000, // Timeout in milliseconds
    headers: {
        'Content-Type': 'application/json',
    },
    params: {},
});

// Optional: Add interceptors for request/response
axiosInstance.interceptors.request.use(
    config => {
        // Modify config if needed
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        // Process response if needed
        return response;
    },
    error => {
        // Handle errors globally
        console.error('Axios Error:', error.response || error.message);
        return Promise.reject(error);
    }
);

export default axiosInstance;



