// Import necessary modules
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { Grid, Button, MenuItem, Select, InputLabel, FormControl, Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MyTextField from '../../@common/MyTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { states } from '../../constatnts/states';
import { countries } from '../../constatnts/countries';
import axiosInstance from '../../axios.config';
import { capitalizeSentence } from '../../utils/capitalize';
import { createPayload } from '../../utils/createPayload';
import { toast, ToastContainer } from 'react-toastify';



// Define a custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#F47920',
        },
    },
});

// Define form data types
interface FormData {
    organisationName?: string;
    addressLine1?: string;
    addressLine2?: string;
    zipCode?: string;
    state: string;
    country: string;
    email: string;
    contactNumber: string;
    firstName: string;
    lastName: string;
}

const getStates = (country: string): any => {
    if (country === '') return '';
    const countryObj = states.filter(con => con.name === country)
    return countryObj[0]?.states
}

const InitialFromValues = {
    state: '',
    country: '',
    organisationName: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    email: '',
    contactNumber: '',
    firstName: '',
    lastName: '',
}

const validationSchema = yup.object().shape({
    organisationName: yup.string(),
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    zipCode: yup
        .string().required('Zip Code is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    email: yup
        .string()
        .email('Invalid email address')
        .required('Email is required'),
    contactNumber: yup
        .string()
        .matches(/^[0-9]+$/, 'Invalid Contact Number')
        .required('Contact Number is required'),
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
});

const RegistrationForm: React.FC = () => {
    const [loading, setLoading] = useState(false)

    const methods = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: InitialFromValues,
        mode: 'onSubmit'
    });
    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = methods;

    useEffect(() => {
        reset()
    }, [])



    const sendVerificationEmail = async (id: string) => {
        try {
            const res = await axiosInstance.put(`tenants/email-verification/${id}?baseURL=https://clm.sundeus.com/app/verify-email?code=`)
            if (res.status === 200) {
                toast.success(res.data)
                setTimeout(() => {
                    window.location.href = "https://clm.sundeus.com/login";
                }, 3000);

            }
        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
        }

    }


    const onSubmit: SubmitHandler<FormData> = async (data) => {

        try {
            setLoading(true)
            const res = await axiosInstance.post('tenants', {}, {
                params: createPayload(data),
            });
            if (res.data.status === 400) {
                toast.error('Email already exists!')
                setLoading(false)
            }

            if (res.data.id) {
                sendVerificationEmail(res.data.id)
            }

        } catch (error) {
            console.log('error');
            setLoading(false)
        }
    };

    const handleReset = () => {
        reset(InitialFromValues)
    }

    return (
        <ThemeProvider theme={theme}>
            <ToastContainer position="top-center"
                autoClose={5000} theme='colored' />
            <FormProvider {...methods}>
                <Container maxWidth="lg" sx={{ py: 10 }}>
                    <Grid container sx={{ my: 5 }}>
                        <Typography variant='h3' >Registration Form</Typography>
                    </Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {/* Organisation Name */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="organisationName"
                                    control={control}
                                    render={({ field }) => <MyTextField {...field} label="Organisation Name" />}
                                />
                            </Grid>

                            {/* Address Line 1 */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="addressLine1"
                                    control={control}
                                    render={({ field }) => <MyTextField {...field} label="Address Line 1" />}
                                />
                            </Grid>

                            {/* Address Line 2 */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="addressLine2"
                                    control={control}
                                    render={({ field }) => <MyTextField {...field} label="Address Line 2" />}
                                />
                            </Grid>

                            {/* Zip Code */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="zipCode"
                                    control={control}
                                    render={({ field }) => (
                                        <MyTextField
                                            {...field}
                                            label="Zip Code"
                                            error={!!errors.zipCode}
                                            helperText={errors.zipCode ? errors.zipCode.message : ''}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Country */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl fullWidth sx={{
                                            borderColor: 'white',
                                            '& fieldset': { border: 'none' },
                                            backgroundColor: 'rgba(145, 158, 171, 0.13)',
                                            py: 0.5,
                                            mx: 1,
                                        }}>
                                            <InputLabel>Country</InputLabel>
                                            <Select {...field} label="Country">
                                                {countries.map((country) => (
                                                    <MenuItem key={country.id} value={capitalizeSentence(country.name)}>
                                                        {capitalizeSentence(country.name)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>

                            {/* State */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl fullWidth sx={{
                                            borderColor: 'white',
                                            '& fieldset': { border: 'none' },
                                            backgroundColor: 'rgba(145, 158, 171, 0.13)',
                                            py: 0.5,
                                            mx: 1,
                                        }}>
                                            <InputLabel>State</InputLabel>
                                            <Select {...field} label="State" disabled={!watch('country')}>
                                                {watch('country') && getStates(watch('country'))?.map((state: any) => (
                                                    <MenuItem key={state.name} value={state.name}>
                                                        {state.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>

                            {/* Email */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <MyTextField
                                            {...field}
                                            label="Email"
                                            type="email"
                                            error={!!errors.email}
                                            helperText={errors.email ? errors.email.message : ''}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Contact Number */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="contactNumber"
                                    control={control}
                                    render={({ field }) => (
                                        <MyTextField
                                            {...field}
                                            label="Contact Number"
                                            type="text"
                                            error={!!errors.contactNumber}
                                            helperText={errors.contactNumber ? errors.contactNumber.message : ''}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* First Name (Admin) */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field }) => (
                                        <MyTextField
                                            {...field}
                                            label="First Name (Admin)"
                                            error={!!errors.firstName}
                                            helperText={errors.firstName ? errors.firstName.message : ''}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Last Name (Admin) */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field }) => (
                                        <MyTextField
                                            {...field}
                                            label="Last Name (Admin)"
                                            error={!!errors.lastName}
                                            helperText={errors.lastName ? errors.lastName.message : ''}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Submit Button */}
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }} gap={2} >
                                <Button onClick={handleReset} sx={{ color: 'Black' }} variant="outlined" color="primary">
                                    reset
                                </Button>
                                <Button disabled={loading} type="submit" sx={{ color: 'white' }} variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </FormProvider>
        </ThemeProvider>
    );
};

export default RegistrationForm;
