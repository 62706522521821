// customHook.js
import { useState, useEffect } from 'react';

/**
 * Custom hook to determine if the current screen size is mobile or desktop.
 * @returns {string} - "mobile" if the screen is mobile-sized, "desktop" otherwise.
 */
const useDeviceType = () => {
    const [deviceType, setDeviceType] = useState(
        window.innerWidth <= 768 ? 'mobile' : 'desktop'
    );

    useEffect(() => {
        const handleResize = () => {
            setDeviceType(window.innerWidth <= 768 ? 'mobile' : 'desktop');
        };

        // Add event listener for resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return deviceType;
};

export default useDeviceType;
