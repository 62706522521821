import { getCountryId } from "./getCountryId"

export const createPayload = (data: any): any => {
    return {
        "addressLine1": data.addressLine1,
        "addressLine2": data.addressLine2,
        "contactNumber": data.contactNumber,
        "countryId": getCountryId(data.country),
        // "createDate": new Date(),
        "createdBy": data.firstName,
        "firstName": data.firstName,
        // "id": 0,
        // "isDeleted": false,
        "lastName": data.lastName,
        "noOfEmployees": 1,
        "noOfLicensesReq": 1,
        "officialEMail": data.email,
        "orgWebsite": "https://test.com/",
        "organizationName": data.organisationName,
        "planId": 1,
        "registrationNumber": "000",
        "status": 1,
        // "updateCount": 0,
        // "updateDate": "",
        // "updatedBy": "",
        "zipCode": data.zipCode
    }
}

