/**
 * Capitalizes the first letter of each word in a sentence.
 * @param sentence - The sentence to capitalize.
 * @returns A new sentence with each word capitalized.
 */
export function capitalizeSentence(sentence: string): string {
    if (!sentence) return '';

    return sentence
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}
