import { Container, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material'
import Logo from '../../svg/Logo'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import useDeviceType from '../../hooks/useDeviceType';

const Footer = () => {
    const deviceType = useDeviceType();
    return (
        <Grid sx={{ backgroundColor: '#161C24' }}>
            <Container maxWidth="lg">
                <Grid container sx={{ justifyContent: 'space-between', py: 5 }} >
                    <Grid xs={12} md={6} lg={6}>
                        <Logo />
                        <Typography variant='body2' sx={{ color: 'white' }}> Make Things Happen with Sundeus CLM</Typography>
                        <Grid container spacing={2} sx={{ marginTop: 0.5 }}  >
                            <Grid item>
                                <Tooltip title={'LinkedIn'}>
                                    <Link href='https://www.linkedin.com/company/sundeus-technologies-pvt-ltd/' target={'_blank'}>
                                        <IconButton>
                                            <LinkedInIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={'YouTube'}>
                                    <Link href='https://www.youtube.com/@SundeusAI' target={'_blank'}>
                                        <IconButton>
                                            <YouTubeIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={'Instagram'}>
                                    <Link href='https://www.instagram.com/sundeus_technology_social/profilecard/?igsh=MWxhbTQzbnAzM2N0YQ==' target={'_blank'}>
                                        <IconButton>
                                            <InstagramIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Grid>
                            {/* <Grid item>
                                <GitHubIcon sx={{ color: 'white' }} />
                            </Grid> */}
                        </Grid>
                        <Grid container direction={'column'} spacing={1} mt={5} mb={2} sx={{ alignItems: { xs: 'flex-start', md: 'flex-start', lg: 'flex-start' } }} >
                            {deviceType === 'mobile' && <>
                                <Grid item>
                                    <Typography variant='body2' sx={{ color: 'white' }} >Tel. +917768001429</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body2' sx={{ color: 'white' }} >Email: contact@sundeus.com</Typography>
                                </Grid>
                            </>}
                            <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <LocationOnIcon sx={{ color: '#F47920' }} />
                                <Typography variant='body2' sx={{ color: 'white' }} > UK</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' sx={{ color: 'white', textAlign: { xs: 'start', md: 'end', lg: 'end' }, ml: 1 }} >71-75 Shelton Street, London, United Kingdom, WC2H 9JQ</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'column'} spacing={2} sx={{ alignItems: { xs: 'flex-start', md: 'flex-end', lg: 'flex-end' } }} xs={12} md={6} lg={6}>

                        {deviceType === 'desktop' && <>
                            <Grid item>
                                <Typography variant='body2' sx={{ color: 'white' }} >Tel. +917768001429</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' sx={{ color: 'white' }} >Email: contact@sundeus.com</Typography>
                            </Grid>
                        </>}
                        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <LocationOnIcon sx={{ color: '#F47920' }} />
                            <Typography variant='body2' sx={{ color: 'white' }} > Pune, India</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2' sx={{ color: 'white', textAlign: { xs: 'start', md: 'end', lg: 'end' } }} >Office No.909 Gera Imperium Rise, Wipro Circle Hinjewadi Phase 2 Pune (MH) – 411057</Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <LocationOnIcon sx={{ color: '#F47920' }} />
                            <Typography variant='body2' sx={{ color: 'white' }} > Gurugram, India</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2' sx={{ color: 'white', textAlign: { xs: 'start', md: 'end', lg: 'end' } }} >835, B2, SPAZE ITECH PARK, Sector 49, Gurugram, Haryana 122018</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2' sx={{ color: '#F47920' }} >© 2021 by Sundeus Technologies</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}

export default Footer