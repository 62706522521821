import { TextField } from '@mui/material';
import { useFormContext, FieldError } from 'react-hook-form';
import React from 'react';

type MyTextFieldProps = {
    label?: string;
    name: string;
    required?: boolean;
    placeholder?: string;
    type?: string;
    error?: boolean;
    helperText?: string;
};

export default function MyTextField({
    label,
    name,
    required,
    placeholder,
    type,
    error,
    helperText,
}: MyTextFieldProps) {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const Label = () => (
        <>{label} {required && <span style={{ color: 'red' }}>*</span>}</>
    );

    return (
        <TextField
            label={<Label />}
            {...register(name)}
            variant="outlined"
            error={error ?? !!errors[name]}
            placeholder={placeholder}
            helperText={helperText ?? (errors[name] && (errors as any)[name]?.message)}
            fullWidth
            type={type || 'text'}
            sx={{
                borderColor: 'white',
                '& fieldset': { border: 'none' },
                backgroundColor: 'rgba(145, 158, 171, 0.13)',
                py: 0.5,
                mx: 1,
            }}
        />
    );
}
